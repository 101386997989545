<template>
  <div class="home_content">
    <div class="content_box">
      <topNav pageName="home"></topNav>
    </div>
    <div class="swiper_box">
      <swiper
        :modules="modules"
        :loop="true"
        :slides-per-view="1"
        :space-between="0"
        :autoplay="{ delay: 2000, disableOnInteraction: false }"
        :navigation="navigation"
        :pagination="{ clickable: true }"
        :scrollbar="{ draggable: false }"
      >
        <swiper-slide
          ><img src="/static/home/banner.png" style="width: 100%"
        /></swiper-slide>
        <swiper-slide
          ><img src="/static/home/banner.png" style="width: 100%"
        /></swiper-slide>
      </swiper>
      <!-- <img class="logo" src="/static/home/logo.png" /> -->
      <img class="phone wow animate__fadeInRight" src="/static/home/phone.png" />
    </div>
    <div class="content_box">
      <div class="top_box">
        <div class="top_list">
          <div>推拿</div>
          <div>理疗</div>
          <div>泰式SPA</div>
          <div>纤体养生</div>
          <div>通络</div>
          <div>足疗</div>
          <div>采耳</div>
        </div>
        <div class="img_box">
          <img class="wow animate__fadeInLeftBig" src="/static/home/img_1.png" />
          <img class="wow animate__fadeInRightBig" src="/static/home/img_2.png" />
        </div>
      </div>
    </div>
    <!-- 商家展示 -->
    <div class="shop_desc wow animate__fadeInUp">
      <div class="title">
        <div>EXCELLENT</div>
        <div>BUSINESS DISPLAY</div>
        <div>优秀商家展示</div>
      </div>
      <div class="swiper-container" ref="swiperRef">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <div class="img_list">
              <img src="/static/home/js_3.png" />
              <img src="/static/home/js_2.png" />
              <img src="/static/home/js_1.png" />
            </div>
          </div>
          <div class="swiper-slide">
            <div class="img_list">
              <img src="/static/home/js_3.png" />
              <img src="/static/home/js_2.png" />
              <img src="/static/home/js_1.png" />
            </div>
          </div>
        </div>
      </div>
      <div class="tool">
        <img src="/static/home/left.png" @click="handleClickBtn(0)" />
        <img src="/static/home/right.png" @click="handleClickBtn(1)" />
      </div>
    </div>
    <!-- 服务项目 -->
    <div class="project_content">
      <div class="title">SERVICE ITEMS</div>
      <div class="desc">高品质服务项目</div>
      <div class="project_list">
        <div
          class="project_list_item wow animate__fadeInUp"
          v-for="(k, i) of list"
          :key="i"
        >
          <img :src="k.img" />
          <div class="project_list_item_name">{{ k.name }}</div>
          <div class="project_list_item_text" v-if="k.part">
            理疗部位：{{ k.part }}
          </div>
          <div class="project_list_item_text" v-if="k.provide">
            服务姿势：{{ k.provide }}
          </div>
          <div class="project_list_item_text" v-if="k.posture">
            物品提供：{{ k.posture }}
          </div>
          <div class="project_list_item_text" v-if="k.effect">
            作用：{{ k.effect }}
          </div>
          <div class="project_list_item_text" v-if="k.desc">{{ k.desc }}</div>
        </div>
      </div>
    </div>
    <my-footer></my-footer>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";

import myFooter from "@/components/my-footer/index.vue";
import topNav from "@/components/nav/index.vue";
import mySwiper from "swiper";
import { WOW } from "wowjs";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, Pagination, Navigation, Scrollbar } from "swiper";
const modules = [Autoplay, Pagination, Navigation, Scrollbar];
const list = reactive([
  {
    img: "/static/home/x_1.png",
    name: "五行足疗",
    part: "泡脚、肩颈按摩、手臂按摩、足底按摩、（前）腿部按摩、腰背按摩、（后）腿部按摩。",
    provide: "足浴盆、中药浴足包、一次性足浴套、一次性毛巾、蛇油膏",
    posture: "坐姿、平躺、俯卧",
    effect: "全身放松,缓解身体疲劳",
  },
  {
    img: "/static/home/x_2.png",
    name: "泰式古法",
    part: "头部按摩、手部按摩、（前）腿部按摩、腹部（精油）按摩、背部（精油）按摩、（后）腿部按摩。",
    provide: "一次性床单、一次毛巾、植物芳香精油、一次性短裤",
    posture: "平躺、俯卧",
    effect: "全身放松,缓解身体疲劳",
  },
  {
    img: "/static/home/x_3.png",
    name: "中式开背",
    part: "头部按摩、背部（精油）SPA、手臂（精油）SPA、腰部（精油）SPA、臀部中式按摩、后腿（精油）SPA、腹部（精油）SPA、前腿（精油）SPA。",
    provide: "一次性床单、一次毛巾、植物芳香精油、一次性短裤",
    posture: "平躺、俯卧",
    effect: "全身放松,缓解身体疲劳",
  },
  {
    img: "/static/home/x_4.png",
    name: "全身芳疗SPA",
    part: "头、肩颈按摩、背部（精油）SPA、手臂（精油）SPA、腰部（精油）SPA、能量砭石温灸（背部）、臀部中式按摩、后腿（精油）SPA、胸部（精油）SPA、腹部（精油）SPA、能量砭石温灸（腹部）、前腿（精油）SPA。 ",
    provide: "一次性床单、一次毛巾、能量发热砭石、植物芳香精油、一次性短裤 ",
    posture: "平躺、俯卧",
    effect: "全身放松,缓解身体疲劳",
  },
  {
    img: "/static/home/x_5.png",
    name: "芳疗SPA+热灸",
    part: "头部按摩、手臂按摩、（前）腿部按摩、正面泰式拉伸、背部按摩、臀部中式按摩、（后）腿部按摩、背面泰式拉伸、坐姿泰式拉伸",
    provide: "",
    posture: "平躺、俯卧、坐姿",
    effect: "全身放松，缓解身体疲劳",
  },
  {
    img: "/static/home/x_6.png",
    name: "头疗+芳香SPA",
    part: "全身精油SPA（背部、臀部、腿部、胸部、腹部、）面部清洁、眼部、拨经、眼部补水、眼部蒸汽、头部",
    provide:
      "泰国进口纯植物香薰精油、香薰灯、疗效音乐、眼部按摩乳、眼膜、蒸汽眼罩、天然粉水晶玉石、头部经络梳、砭石温灸",
    posture: "俯卧、平躺",
    effect:
      "缓解身体疲劳，眼部抗衰，改善眼部浮肿、黑眼圈、眼角纹、眼部干涩、眼疲劳、眼光无神，眼部衰老，调理睡眠",
  },
  {
    img: "/static/home/x_7.png",
    name: "项目加钟",
    part: "",
    provide: "",
    posture: "",
    effect: "",
    desc: "订单结束30分钟内均可点击加钟",
  },
]);
const swiperRef = ref(null);
const swiperExl = ref(null);
const handleClickBtn = (val) => {
  if (swiperExl.value) {
    // console.log(swiperExl.value);
    swiperExl.value.slideTo(val);
  }
};
const wow_example = new WOW({
  boxClass: "wow",
  animateClass: "animate__animated",
  offset: 0,
  mobile: true,
  live: true,
});
onMounted(() => {
  swiperExl.value = new mySwiper(swiperRef.value, {
    slidesPerView: 1,
  });
  wow_example.init();
});
</script>
<style lang="scss">
.swiper-container {
  overflow: hidden;
}
.home_content {
  .content_box {
  }
  .nav {
    padding-right: 149px;
  }
  .swiper_box {
    position: relative;
    .phone {
      position: absolute;
      bottom: 0px;
      right: 2%;
      width: 15%;
      z-index: 10;
    }
    .logo {
      position: absolute;
      top: 0;
      left: 0;
      width: 11%;
      z-index: 10;
      transform: translateY(-100%);
    }
  }
  .top_box {
    position: relative;
    .top_list {
      box-sizing: border-box;
      background: #80a128;
      display: flex;
      align-items: center;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;

      & > div {
        cursor: pointer;
      }

      & > div:not(:first-child) {
        border-left: 1px solid #fff;
      }
    }

    .img_box {
    }
  }

  .shop_desc {
    background: #00963a;
    position: relative;

    .title {
      font-family: DIN Light;
      font-weight: 400;
      color: #ffffff;
    }

    .img_list {
      display: flex;
      justify-content: space-between;
    }

    .tool {
      position: absolute;
      display: flex;
      justify-content: space-between;
      & > img {
        cursor: pointer;
      }
    }
  }

  .project_content {
    margin: 0 auto;
    .title {
      font-family: DIN Light;
      font-weight: 400;
      color: #606060;
    }

    .desc {
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #606060;
    }

    .project_list {
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-between;
      &_item {
        & > img {
          width: 100%;
        }
        &_name {
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #606060;
        }

        &_text {
          font-family: Source Han Sans CN;
          font-weight: 300;
          color: #606060;
        }
      }
    }
  }
}

@media screen and (min-width: 0px) {
  .carousel {
    height: 110px;
  }
  .top_box {
    position: relative;
    .top_list {
      box-sizing: border-box;
      height: 30px;
      font-size: 14px;
      justify-content: center;
      & > div {
        padding: 0 5px;
        cursor: pointer;
      }
      & > div:not(:first-child) {
        border-left: 1px solid #fff;
      }
    }
    .img_box {
      margin-top: 5px;
      & > img:first-child {
        width: 80%;
        margin: 0 auto;
        display: block;
      }
      & > img:last-child {
        width: 100%;
        margin: 0 auto;
        display: block;
        margin-top: 10px;
      }
    }
  }
  .shop_desc {
    padding: 10px;
    .title {
      font-size: 18px;
      & > div:last-child {
        margin-top: 10px;
        font-size: 20px;
      }
    }

    .img_list {
      margin-top: 20px;
      & > img {
        width: 100px;
      }
    }
    .tool {
      width: 50px;
      height: 20px;
      top: 50px;
      right: 20px;
      & > img {
        width: 20px;
      }
    }
  }
  .project_content {
    margin-top: 20px;
    padding: 0 20px;
    .title {
      font-size: 20px;
    }
    .desc {
      margin-top: 10px;
      font-size: 14px;
    }
    .project_list {
      margin-top: 10px;
      justify-content: space-between;
      &_item {
        width: 45%;
        margin-bottom: 44px;
        & > img {
          width: 100%;
        }
        &_name {
          font-size: 16px;
          margin-top: 10px;
        }

        &_text {
          margin-top: 10px;
          font-size: 12px;
          line-height: 20px;
        }
      }
    }
  }
}
@media screen and (min-width: 768px) {
  .top_box {
    position: relative;
    .top_list {
      box-sizing: border-box;
      height: 30px;
      font-size: 14px;
      justify-content: center;
      & > div {
        padding: 0 5px;
        cursor: pointer;
      }
      & > div:not(:first-child) {
        border-left: 1px solid #fff;
      }
    }
    .img_box {
      margin-top: 5px;
      & > img:first-child {
        width: 80%;
        margin: 0 auto;
        display: block;
      }
      & > img:last-child {
        width: 100%;
        margin: 0 auto;
        display: block;
        margin-top: 10px;
      }
    }
  }
  .shop_desc {
    padding: 10px;
    .title {
      font-size: 18px;
      & > div:last-child {
        margin-top: 10px;
        font-size: 20px;
      }
    }

    .img_list {
      margin-top: 20px;
      & > img {
        width: 100px;
      }
    }
    .tool {
      width: 50px;
      height: 20px;
      top: 50px;
      right: 20px;
      & > img {
        width: 20px;
      }
    }
  }
  .project_content {
    margin-top: 20px;
    padding: 0 20px;
    .title {
      font-size: 20px;
    }
    .desc {
      margin-top: 10px;
      font-size: 14px;
    }
    .project_list {
      margin-top: 10px;
      justify-content: space-between;
      &_item {
        width: 45%;
        margin-bottom: 44px;
        & > img {
          width: 100%;
        }
        &_name {
          font-size: 16px;
          margin-top: 10px;
        }

        &_text {
          margin-top: 10px;
          font-size: 12px;
          line-height: 20px;
        }
      }
    }
  }
}
@media screen and (min-width: 998px) {
  .carousel {
    height: 440px;
  }
  .content_box {
    width: 1400px;
    margin: 0 auto;
  }
  .top_box {
    position: relative;
    .top_list {
      box-sizing: border-box;
      height: 42px;
      padding-left: 496px;
      font-size: 21px;
      & > div {
        padding: 0 10px;
        cursor: pointer;
      }

      & > div:not(:first-child) {
        border-left: 1px solid #fff;
      }
    }

    .img_box {
      margin-top: 59px;
      padding-left: 30px;
      display: flex;
      justify-content: space-between !important;
      & > img:first-child {
        width: 400px;
      }
      & > img:last-child {
        width: 900px;
      }
    }
  }
  .shop_desc {
    width: 1400px;
    height: 852px;
    margin: 197px auto 188px auto;
    padding: 60px;
    .title {
      font-size: 43px;
      & > div:last-child {
        font-size: 29px;
        margin-top: 20px;
      }
    }

    .img_list {
      display: flex;
      justify-content: space-between;
      margin-top: 60px;

      & > img {
        width: 400px;
      }
    }

    .tool {
      width: 155px;
      height: 55px;
      top: 150px;
      right: 87px;
      & > img {
        width: 55px;
      }
    }
  }
  .project_content {
    width: 1400px;
    margin: 0 auto;
    margin-top: 188px;

    .title {
      font-size: 43px;
    }

    .desc {
      margin-top: 21px;
      font-size: 29px;
    }
    .project_list {
      margin-top: 55px;
      display: flex;
      flex-wrap: wrap;
      justify-content: start;
      &_item {
        width: 320px;
        margin-bottom: 44px;
        & > img {
          width: 100%;
        }
        &_name {
          font-size: 21px;
          margin-top: 15px;
        }

        &_text {
          margin-top: 10px;
          font-size: 13px;
          line-height: 33px;
        }
      }
      & > .project_list_item:not(:nth-child(4n + 1)) {
        margin-left: 20px;
      }
    }
  }
}
</style>
